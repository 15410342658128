export const ModelData = {
    name: '冷暖自然',
    content: [
        {
            title: '👋 Hello',
            content: ['新朋友你好呀，很高兴你能访问我的个人主页，无论你是千里迢迢还是误打误撞来到这里，我都想表示热烈的欢迎 👏']
        },
        {
            title: '👨🏻‍💻 概要',
            content: [
                '差点没赶上99年末班车的一名 Java 开发者，热爱一切美好的事物。',
            ]
        },
        {
            title: '❤️ 热爱美好',
            content: [
                '🛠️ 做开发，不爱写代码的程序员不是好码农',
                '📷 摄影，喜欢拍建筑和风景，各式各样独具魅力的建筑和风景',
                '🎨 设计，其实啥也不会，又菜又爱玩',
                '🏎️ 打游戏，喜欢玩竞速类也只能玩竞速类游戏（3D眩晕晚期 🥹）。',
            ]
        },
        {
            title: '💼 工作',
            content: [
                '2019年11月-至今 ｜ Java 开发',
            ]
        },
        {
            title: '🚀 人生愿望',
            content: [
                '既能够朝九晚五，又能够浪迹天涯',
            ]
        }
    ]
}